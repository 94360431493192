jQuery(document).ready(function($) {

	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		}
	});

	$('.datepicker').datetimepicker({
		language: 'hu',
		weekStart: 1,
		autoclose: 1,
		todayHighlight: 1,
		startView: 2,
		minView: 2,
		maxView: 4,
		format: 'yyyy-mm-dd',
		startDate: new Date()
	});

	$("a.level").each(function(i,o){
		var c = $(o).html().replace('kukac', String.fromCharCode(64));
		c = c.replace(RegExp(/pont/g), String.fromCharCode(46));
		$(o).attr('href', 'mai'+'lt'+'o:'+c).html(c);
	});

	$('.fancybox').fancybox({
		afterLoad: function() {
			this.title+= '<div class="sorszam"><span class="color-turkiz">' + (this.index+1) + '</span>/' + $('.fancybox').length + "</div>";
		},
		helpers:  {
			title : {
				type : 'inside',
				position: 'top'
			},
			overlay : {
				css : {
					'background' : 'rgba(255,255,255,0.95)'
				}
			},
			thumbs	: {
				width	: 150,
				height	: 150
			}
		},
		nextEffect: 'fade',
		prevEffect: 'fade'
	});

	$("body").on("keyup change", ".input-only-digit", function(){
		this.value = this.value.replace(/\D/g, "");
	});

	var gmaps_id = '#google-map';

	if($(gmaps_id).length) {
		var hotel = {
			lat: 45.853659,
			lng: 18.233905
		};

		var map = new GMaps({
			div: gmaps_id,
			lat: hotel.lat,
			lng: hotel.lng,
			disableDefaultUI: true,
			scrollwheel: false,
			mapTypeControl: true,
			zoomControl: true
		});
		map.addMarker({
			lat: hotel.lat,
			lng: hotel.lng,
			title: 'Hotel Lídia ***'
		});
	}

	$(".accordion").on('show.bs.collapse', function (e) {
		$panel = $(e.target).parents(".panel");
		//$panel.siblings().removeClass("nyitva");
		$panel.addClass("nyitva");
	}).on('hide.bs.collapse', function (e) {
		$panel = $(e.target).parents(".panel");
		$panel.removeClass("nyitva");
	});

	var $slides = {
			logo: $(".navbar-logo .slide"),
			highlight: $("#highlight .slide")
		},
		slide_timeout = 4000;

	if($slides.logo.length && $slides.highlight.length){
		var slide_step = function(){
			var $active = {
				logo: $(".navbar-logo .slide.active"),
				highlight: $("#highlight .slide.active")
			};

			var $next = {
				logo: $active.logo.next(".slide"),
				highlight: $active.highlight.next(".slide"),
			};

			if(!$next.logo.length && !$next.highlight.length) {
				$next.logo = $slides.logo.first();
				$next.highlight = $slides.highlight.first();
			}

			$active.logo.removeClass("active");
			$next.logo.addClass("active");
			$active.highlight.removeClass("active");
			$next.highlight.addClass("active");

			setTimeout(slide_step, slide_timeout);
		};

		setTimeout(slide_step, slide_timeout);
	}

});